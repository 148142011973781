import React from "react";
import useSessionStorage from "../hooks/useSessionStorage";
import { useAppSelector } from "store/hooks";
import { isPasswordAboutToExpire } from "store/User.slice";
import { SESSION_STORAGE_KEYS } from "utils/constants";

const PassWarningBanner = () => {
  const [isDismissed, setIsDismissed] = useSessionStorage(
    SESSION_STORAGE_KEYS.IGNORE_PASSWORD_EXPIRY_WARNING,
    false
  );
  const _isPasswordAboutToExpire = useAppSelector(isPasswordAboutToExpire);
  const handleDismiss = () => {
    setIsDismissed(true); // Persist dismissal in sessionStorage
  };

  if (!_isPasswordAboutToExpire || isDismissed) {
    return null; // Don't show the warning if dismissed
  }

  return (
    <div
      style={{
        border: "1px solid orange",
        padding: "10px",
        marginBottom: "20px",
        position: "fixed",
        zIndex: "9999999",
        backgroundColor: "red",
        width: "100%",
        textAlign: "center",
      }}
    >
      <p>⚠️ Your password will expire soon. Please update it!</p>
      <button onClick={handleDismiss}>Dismiss</button>
    </div>
  );
};

export default PassWarningBanner;
