import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { IUserDetails, IUserState } from 'types/UserSlice';
import { RootState } from './store';

const PASSWORD_EXPIRY_DAYS: number = parseInt(process.env.PASSWORD_EXPIRY_DAYS || "60", 10);
const PASSWORD_EXPIRY_THRESHOLD_DAYS: number = parseInt(
  process.env.PASSWORD_EXPIRY_THRESHOLD_DAYS || "7",
  10
);

/**
 * Calculates whether the password is about to expire.
 * @param passwordChangedAt - The date when the password was last changed (ISO string or Date object).
 * @returns True if the password is about to expire, otherwise false.
 */
const calculatePasswordExpiry = (passwordChangedAt: string | Date): boolean => {
  if (!passwordChangedAt) return false;

  const expiryDate = new Date(passwordChangedAt);
  expiryDate.setDate(expiryDate.getDate() + PASSWORD_EXPIRY_DAYS);

  const daysRemaining = Math.ceil((expiryDate.getTime() - new Date().getTime()) / (1000 * 60 * 60 * 24));

  console.log('xxxx', daysRemaining, PASSWORD_EXPIRY_THRESHOLD_DAYS);
  return daysRemaining <= PASSWORD_EXPIRY_THRESHOLD_DAYS;
};

const initialState: IUserState = {
  info: {
    accessToken: '',
    id: '',
    name: '',
    email: '',
    phone: '',
    role: '',
    createdAt: '',
    updatedAt: '',
    passwordChangedAt: '',
  },
  isPasswordAboutToExpire: false
};

const UserDetailsSlice = createSlice({
  name: 'userDetails',
  initialState,
  reducers: {
    setUserDetails: (
      state: IUserState,
      { payload }: PayloadAction<IUserDetails>
    ) => {
      state.info = { ...payload };
      if (payload?.passwordChangedAt) {
        const isPasswordAboutToExpire = payload?.passwordChangedAt
          ? calculatePasswordExpiry(payload?.passwordChangedAt)
          : false
        state.isPasswordAboutToExpire = isPasswordAboutToExpire
      }
    },
    setIsPasswordExpire: (state: IUserState, { payload }: PayloadAction<{ passwordChangedAt: string }>) => {
      console.log("setting pass", payload)
      if (payload?.passwordChangedAt) {
        const isPasswordAboutToExpire = payload?.passwordChangedAt
          ? calculatePasswordExpiry(payload?.passwordChangedAt)
          : false
        state.isPasswordAboutToExpire = isPasswordAboutToExpire
      }
    },
    clearUserDetails: (state: IUserState) => {
      state.info = initialState.info;
    },
  },
});

// Selectors
export const getUserDetails = (state: RootState) => state.userDetails.info;
export const isPasswordAboutToExpire = (state: RootState) => state.userDetails.isPasswordAboutToExpire;

// Actions
export const { setUserDetails, clearUserDetails, setIsPasswordExpire } = UserDetailsSlice.actions;

export default UserDetailsSlice.reducer;
