import { useState } from 'react';

/**
 * Custom hook for managing state with sessionStorage.
 * @param key - The key for the sessionStorage item.
 * @param initialValue - The initial value if the key does not exist in sessionStorage.
 * @returns A tuple containing the stored value and a setter function.
 */
function useSessionStorage<T>(key: string, initialValue: T): [T, (value: T | ((prevValue: T) => T)) => void] {
    // Get initial value from sessionStorage or use the default
    const [storedValue, setStoredValue] = useState<T>(() => {
        try {
            const item = sessionStorage.getItem(key);
            return item ? JSON.parse(item) : initialValue;
        } catch (error) {
            console.error(`Error reading sessionStorage key "${key}":`, error);
            return initialValue;
        }
    });

    // Setter function to update state and sessionStorage
    const setValue = (value: T | ((prevValue: T) => T)) => {
        try {
            const valueToStore = value instanceof Function ? value(storedValue) : value;

            setStoredValue(valueToStore);

            sessionStorage.setItem(key, JSON.stringify(valueToStore));
        } catch (error) {
            console.error(`Error setting sessionStorage key "${key}":`, error);
        }
    };

    return [storedValue, setValue];
}

export default useSessionStorage;
